import { IOrder } from './interfaces';

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum OrderOperation {
  LENGTH = 'length',
}

export type ChangeOrderCallback = (
  currentOrders: IOrder[],
  target: string,
  targetIndex: number
) => IOrder[];

export interface IOnChangeOrderCallbacks {
  noSort?: ChangeOrderCallback;
  desc?: ChangeOrderCallback;
  asc?: ChangeOrderCallback;
}
