import Auth from '@aws-amplify/auth';
import { Action, AuthEntity } from '@utils/userPermissions';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Navbar from '@components/Layout/Navbar/Navbar';
import QuerySpinner from '@components/QuerySpinner/QuerySpinner';
import PrivateRoute from './PrivateRoute';

import DefaultNavigationHeader from './DefaultNavigationHeader';
import routes from './routes';

import {
  saveCurrentSession,
  savePermissions,
} from '@utils/userPermissions/actions';
import { fetchQuery } from '../relay/relayEnvironment';
import NotFound from './NotFound';
import { NotificationBar } from '@components/Layout/NotificationBar';

const AccountEditorContainer = React.lazy(
  () => import('@components/Accounts/AccountEditor/AccountEditorContainer')
);
const AccountCreatorContainer = React.lazy(
  () => import('@components/Accounts/AccountCreator/AccountCreatorContainer')
);
const PartnersOverview = React.lazy(
  () => import('@components/Partners/PartnersOverview/PartnersOverview')
);
const PartnerCreatorContainer = React.lazy(
  () => import('@components/Partners/PartnerCreator/PartnerCreator')
);
const AccountsOverviewContainer = React.lazy(
  () =>
    import('@components/Accounts/AccountsOverview/AccountsOverviewContainer')
);

const ApartmentEditorContainer = React.lazy(
  () =>
    import('@components/Apartments/ApartmentEditor/ApartmentEditorContainer')
);
const ApartmentCreator = React.lazy(
  () => import('@components/Apartments/ApartmentCreator')
);
const ApartmentsOverviewContainer = React.lazy(
  () =>
    import(
      '@components/Apartments/ApartmentsOverview/ApartmentsOverviewContainer'
    )
);

const HouseCreator = React.lazy(
  () => import('@components/Houses/HouseCreator')
);
const HousesOverview = React.lazy(
  () => import('@components/Houses/HousesOverview/HousesOverview')
);
const HouseEditorContainer = React.lazy(
  () => import('@components/Houses/HouseEditor/HouseEditorContainer')
);

const ListingEditor = React.lazy(
  () => import('@components/Listings/ListingEditor/ListingEditorContainer')
);
const ListingCreator = React.lazy(
  () => import('@components/Listings/ListingCreator')
);
const ListingsOverviewContainer = React.lazy(
  () =>
    import('@components/Listings/ListingsOverview/ListingsOverviewContainer')
);

const ProjectFormContainer = React.lazy(
  () => import('@components/Projects/ProjectForm/ProjectFormContainer')
);
const ProjectCreatorContainer = React.lazy(
  () => import('@components/Projects/ProjectCreator/ProjectCreatorContainer')
);
const ProjectsOverview = React.lazy(
  () => import('@components/Projects/ProjectsOverview/ProjectsOverview')
);

const ParkingLotEditorContainer = React.lazy(
  () =>
    import('@components/ParkingLots/ParkingLotEditor/ParkingLotEditorContainer')
);
const ParkingLotCreatorContainer = React.lazy(
  () =>
    import(
      '@components/ParkingLots/ParkingLotCreator/ParkingLotCreatorContainer'
    )
);
const ParkingLotsOverviewContainer = React.lazy(
  () =>
    import(
      '@components/ParkingLots/ParkingLotsOverview/ParkingLotsOverviewContainer'
    )
);

const Wrapper = styled('div')`
  min-height: 100vh;
  background: ${({ theme }) => theme.colours.grey.lightest};
  display: flex;
`;

const ComponentWrapper = styled.div`
  position: relative;
  padding-left: 85px;
  width: 100%;
`;

const HeaderWrapper = styled('div')`
  position: sticky;
  z-index: 100;
  top: 0;
  height: 80px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
`;

const BodyWrapper = styled('div')`
  min-height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
`;

const LoggedInLayout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [status, setStatus] = React.useState<'idle' | 'resolved' | 'pending'>(
    'idle'
  );

  React.useEffect(() => {
    if (status === 'resolved') return;

    const setUserPermissions = async () => {
      try {
        const currentUserSession = await Auth.currentSession();

        const { data } = await fetchQuery({
          text: `{
            userPermissions {
              id,
              role,
              entity,
              action,
              attributes
            }
          }`,
        });

        dispatch(saveCurrentSession(currentUserSession));
        dispatch(savePermissions(data?.userPermissions ?? []));
        setStatus('resolved');
      } catch (error) {
        console.error(error);
        history.push(routes.auth.login.get());
      }
    };

    setUserPermissions();
  }, [dispatch, history, status]);

  if (['idle', 'pending'].includes(status))
    return <QuerySpinner wrapper={{ mb: '256px' }} />;

  return (
    <Wrapper>
      <Navbar />
      <ComponentWrapper>
        <HeaderWrapper>
          <DefaultNavigationHeader />
          <NotificationBar />
        </HeaderWrapper>
        <BodyWrapper>
          <React.Suspense fallback={<QuerySpinner wrapper={{ mb: '256px' }} />}>
            <Switch>
              {/* Account */}
              <PrivateRoute
                exact
                path={routes.account.create.path}
                component={AccountCreatorContainer}
                authAction={Action.Create}
                authEntity={AuthEntity.user}
                authRedirect={routes.account.home.get()}
              />
              <PrivateRoute
                exact
                path={routes.account.edit.path}
                component={AccountEditorContainer}
                authAction={Action.Update}
                authEntity={AuthEntity.user}
                authRedirect={routes.account.home.get()}
              />
              <PrivateRoute
                exact
                path={routes.account.home.path}
                component={AccountsOverviewContainer}
                authAction={Action.Create}
                authEntity={AuthEntity.user}
              />

              {/* Apartments */}
              <Route
                exact
                path={routes.apartment.home.path}
                component={ApartmentsOverviewContainer}
              />
              <PrivateRoute
                exact
                path={routes.apartment.create.path}
                component={ApartmentCreator}
                authAction={Action.Create}
                authEntity={AuthEntity.apartment}
                authRedirect={routes.apartment.home.get()}
              />
              <PrivateRoute
                exact
                path={routes.apartment.edit.path}
                component={ApartmentEditorContainer}
                authAction={Action.Update}
                authEntity={AuthEntity.apartment}
                authRedirect={routes.apartment.home.get()}
              />

              {/* Houses */}
              <Route
                exact
                path={routes.house.home.path}
                component={HousesOverview}
              />
              <PrivateRoute
                exact
                path={routes.house.create.path}
                component={HouseCreator}
                authAction={Action.Create}
                authEntity={AuthEntity.house}
                authRedirect={routes.house.home.get()}
              />
              <PrivateRoute
                exact
                path={routes.house.edit.path}
                component={HouseEditorContainer}
                authAction={Action.Update}
                authEntity={AuthEntity.house}
                authRedirect={routes.house.home.path}
              />

              {/* Listings */}
              <Route
                exact
                path={routes.listing.home.path}
                component={ListingsOverviewContainer}
              />
              <PrivateRoute
                exact
                path={[routes.listing.clone.path, routes.listing.create.path]}
                component={ListingCreator}
                authAction={Action.Create}
                authEntity={AuthEntity.listing}
                authRedirect={routes.listing.home.get()}
              />
              <PrivateRoute
                exact
                path={routes.listing.edit.path}
                component={ListingEditor}
                authAction={Action.Update}
                authEntity={AuthEntity.listing}
                authRedirect={routes.listing.home.get()}
              />

              {/* ParkingLots */}
              <Route
                exact
                path={routes.parkingLot.home.path}
                component={ParkingLotsOverviewContainer}
              />
              <PrivateRoute
                exact
                path={routes.parkingLot.create.path}
                component={ParkingLotCreatorContainer}
                authAction={Action.Create}
                authEntity={AuthEntity.parkingLot}
                authRedirect={routes.parkingLot.home.get()}
              />
              <PrivateRoute
                exact
                path={routes.parkingLot.edit.path}
                component={ParkingLotEditorContainer}
                authAction={Action.Update}
                authEntity={AuthEntity.parkingLot}
                authRedirect={routes.parkingLot.home.get()}
              />

              {/* Projects */}
              <Route
                exact
                path={routes.project.home.path}
                component={ProjectsOverview}
              />
              <PrivateRoute
                exact
                path={routes.project.create.path}
                component={ProjectCreatorContainer}
                authAction={Action.Create}
                authEntity={AuthEntity.project}
                authRedirect={routes.project.home.get()}
              />
              <PrivateRoute
                exact
                path={routes.project.edit.path}
                component={ProjectFormContainer}
                authAction={Action.Update}
                authEntity={AuthEntity.project}
                authRedirect={routes.project.home.get()}
              />

              {/* Patners */}
              <Route
                exact
                path={routes.partner.home.path}
                component={PartnersOverview}
              />
              <PrivateRoute
                exact
                path={routes.partner.create.path}
                component={PartnerCreatorContainer}
                authAction={Action.Create}
                authEntity={AuthEntity.company}
                authRedirect={routes.partner.create.get()}
              />
              <Route component={NotFound} />
            </Switch>
          </React.Suspense>
        </BodyWrapper>
      </ComponentWrapper>
    </Wrapper>
  );
};

export default LoggedInLayout;
