import { Flex } from 'rebass';
import styled from 'styled-components';
import { ReactComponent as Dots } from '@assets/images/dots-green.svg';

interface IWrapper {
  translateY?: string;
  translateX?: string;
  position?: string;
}

export const PopoverWrapper = styled('div')<IWrapper>`
  z-index: 5;
  padding: 7px 0;
  width: 347px;
  background: white;
  position: absolute;
  transform: translate(-281px, -100px);
  border-radius: 3px;
  border: 1px solid rgb(213, 213, 213);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);

  :after {
    content: '';
    position: absolute;
    right: 35px;
    border-style: solid;
    border-color: #fff transparent;
    display: block;
    width: 0;
    border-width: 10px 10px 0;
    bottom: -10px;
  }

  > button {
    width: 100%;
    font-size: 14px;
    cursor: pointer;

    ${({ theme }) => `
      :hover {
        background: ${theme.colours.primaryHighlight};
        color: ${theme.colours.primary};

        svg:not(.lang-icon) {
          circle {
            stroke: ${theme.colours.primary};
          }
          path {
            fill: ${theme.colours.primary};
          }
        }
      }
    `}
  }
`;

export const ToggleIcon = styled(Dots)`
  path {
    fill: #fff;
  }

  height: 40px;
  width: 40px;
`;

export const PopoverOptionWrapper = styled(Flex)<{ isLocked?: boolean }>`
  padding: 15px 27px;

  svg {
    circle {
      stroke: #000;
    }
    path {
      fill: #000;
    }
  }

  .title,
  .description {
    text-align: left;
    margin: 0;
  }

  .title {
    font-size: 16px;
  }

  .description {
    font-size: 14px;
    color: rgb(172, 172, 172);
  }

  ${({ theme }) => `
    :hover {
      background: ${theme.colours.primaryHighlight};
      color: ${theme.colours.primary};

      svg {
        circle {
          stroke: ${theme.colours.primary};
        }
        path {
          fill: ${theme.colours.primary};
        }
      }
    }
  `}

  ${({ isLocked }) =>
    isLocked &&
    `
    cursor: not-allowed;
  `}
`;

export const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
`;
