import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LanguageEnum } from '@src/i18n';

const LocaleSelectWrapper = styled.div`
  margin-left: 10px;
  display: inline-flex;
  flex-direction: column;
  width: 28px;
  button:nth-child(1) {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  button:nth-child(2) {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const LocaleSelectButton = styled.button<{ isSelected: boolean }>`
  width: 100%;
  font-size: 12px;
  padding: 2px 5px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colours.primary : 'transparent'};
  color: ${(props) =>
    props.isSelected ? 'white' : props.theme.colours.grey.light};
  ${(props) =>
    !props.isSelected && `border: 1px solid ${props.theme.colours.grey.light}`};
`;

const LocaleSelect: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeToEn = () => {
    if (currentLanguage !== LanguageEnum.english) {
      i18n.changeLanguage(LanguageEnum.english);
    }
  };

  const changeToDe = () => {
    if (currentLanguage !== LanguageEnum.german) {
      i18n.changeLanguage(LanguageEnum.german);
    }
  };

  return (
    <LocaleSelectWrapper>
      <LocaleSelectButton
        onClick={changeToEn}
        isSelected={currentLanguage === LanguageEnum.english}
      >
        EN
      </LocaleSelectButton>
      <LocaleSelectButton
        onClick={changeToDe}
        isSelected={currentLanguage === LanguageEnum.german}
      >
        DE
      </LocaleSelectButton>
    </LocaleSelectWrapper>
  );
};

export default LocaleSelect;
