import Amplify from '@aws-amplify/core';

const {
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
  COGNITO_USER_POOL_WEB_CLIENT_ID,
  COGNITO_DOMAIN,
  OAUTH_REDIRECT_SIGN_OUT,
  OAUTH_REDIRECT_SIGN_IN,
} = process.env;

const config = () =>
  Amplify.configure({
    Auth: {
      identityPoolId: COGNITO_IDENTITY_POOL_ID,
      region: COGNITO_REGION,
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolWebClientId: COGNITO_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        domain: COGNITO_DOMAIN,
        scope: ['email'],
        redirectSignIn: OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: OAUTH_REDIRECT_SIGN_OUT,
        responseType: 'token',
      },
    },
  });

export default config;
