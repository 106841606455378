export enum UserGroups {
  MarketingManager = 'Marketing Manager',
  OperationManager = 'Operations Manager',
  ProjectManager = 'Project Manager',
  Admin = 'Admin',
  MainUser = 'Main User',
}

const mapUserGroup = (userGroup: string) => {
  switch (userGroup) {
    case 'ADMIN':
      return UserGroups.Admin;
    case 'MARKETING_MANAGER':
      return UserGroups.MarketingManager;
    case 'OPERATION_MANAGER':
      return UserGroups.OperationManager;
    case 'PROJECT_MANAGER':
      return UserGroups.ProjectManager;
    case 'MAIN_USER':
      return UserGroups.MainUser;
    default:
      return '';
  }
};

export default mapUserGroup;
