import { ArrowRight } from '@assets/images';
import AccountWrapper, {
  ACCOUNT_WRAPPER_WIDTH,
} from '@components/Layout/Header/AccountWrapper';
import LocaleSelect from '@components/Locale/LocaleSelect';
import CurrentUser from '@src/components/User/CurrentUser';
import { camelCase } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Box } from 'rebass';
import styled from 'styled-components';
import Header from '../components/Layout/Header/Header';
import routes, { IRouteRecordType } from './routes';

export const Title = styled('h1')`
  font-family: FuturaLight;
  font-size: 27px;
  font-weight: 300;
  margin: 0;
  color: rgb(155, 155, 155);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

const AfterTitlePortalNode = styled('div')`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% - ${ACCOUNT_WRAPPER_WIDTH}px);
`;

const RightDynamicComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.colours.grey.lighter};
`;

const LeftDynamicComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const falseyValues = (value: any) => !!value;

const getCurrentRouteRecord = (
  pathname: string
): IRouteRecordType | undefined => {
  const flattenRoutes = Object.values(routes).reduce<IRouteRecordType[]>(
    (acc, curr) => [...acc, ...Object.values(curr)],
    []
  );

  return flattenRoutes.find((r) =>
    new RegExp(r.path.replace(/:[^/]+/g, '[^/]+')).test(pathname)
  );
};

export const DefaultNavigationHeader = () => {
  const location = useLocation();
  const route = React.useMemo(
    () => getCurrentRouteRecord(location.pathname),
    [location.pathname]
  );

  const { t } = useTranslation([route?.translationNamespace ?? '']);
  const isSetSubtitleProp = !!route?.subtitle;

  let subtitleArr: string[] = [];

  if (route?.subtitle) {
    subtitleArr = Array.isArray(route.subtitle)
      ? route.subtitle.filter(falseyValues)
      : [route.subtitle];
  }

  return (
    <Header id="navigation-header">
      <LeftDynamicComponentWrapper id="header-dynamic-component-left" />
      <TitleWrapper>
        <Title>
          {t(
            `${route?.translationNamespace}:${camelCase(route?.title)}`,
            route?.title
          )}
        </Title>
        {isSetSubtitleProp ? (
          subtitleArr.map((subtitle, index) => (
            <React.Fragment key={index}>
              <Box ml={10} mr={10}>
                <ArrowRight />
              </Box>
              <Title>{subtitle}</Title>
            </React.Fragment>
          ))
        ) : (
          <AfterTitlePortalNode id="after-title-portal" />
        )}
      </TitleWrapper>
      <RightDynamicComponentWrapper id="header-dynamic-component-right" />
      <AccountWrapper>
        <CurrentUser />
        <LocaleSelect />
      </AccountWrapper>
    </Header>
  );
};

export default DefaultNavigationHeader;
