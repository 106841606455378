import { Reducer } from 'redux';
import { INotification } from './types';
import { INotificationActions, NotificationActionTypes } from './actions';

export interface INotificationState {
  notification?: INotification;
}

export const initialState: INotificationState = {
  notification: undefined,
};

const reducer: Reducer<INotificationState, INotificationActions> = (
  state = initialState,
  action
): INotificationState => {
  switch (action.type) {
    case NotificationActionTypes.CLEAR_NOTIFICATION: {
      return { ...initialState };
    }
    case NotificationActionTypes.SHOW_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload.notification,
      };
    }
    default:
      return state;
  }
};

export default reducer;
