import { ArrowDown } from '@assets/images';
import React from 'react';
import styled from 'styled-components';

const UserName = styled('label')`
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  svg {
    margin-left: 9.5px;
    height: 12px;
    path {
      fill: #000;
    }
  }
`;

interface IProps {
  username: string;
  onClick: () => void;
}

const ToggleComponent = (props: IProps) => (
  <UserName onClick={props.onClick}>
    {props.username}
    <ArrowDown />
  </UserName>
);

export default ToggleComponent;
