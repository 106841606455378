import { ReactComponent as LogoutIcon } from '@assets/images/logout.svg';
import React from 'react';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { PopoverWrapper } from '../../Layout/Popover/Styled';

interface IProps {
  toggle: () => void;
  logout: () => Promise<void>;
}

const BodyWrapper = styled(PopoverWrapper)`
  width: 148px;
  transform: translate(0px, 28px);
  right: 25px;
  :after {
    right: 25px;
    border-width: 0 10px 10px;
    top: -10px;
    bottom: unset;
  }

  > button {
    height: 40px;
    padding-left: 12.5px;
  }
`;

const BodyComponent = (props: IProps) => {
  const handleOnClick = async () => {
    await props.logout();
    props.toggle();
  };

  return (
    <BodyWrapper>
      <button id="logout-btn" onClick={handleOnClick}>
        <Flex alignItems="center">
          <LogoutIcon />
          <Text ml={11}>Logout</Text>
        </Flex>
      </button>
    </BodyWrapper>
  );
};

export default BodyComponent;
