import { WarningHero } from '@assets/images';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';

export interface INotFoundProps {
  wrapperFillScreen?: boolean;
  heroWidth?: string;
  heroHeight?: string;
  message?: string;
  redirectUrl?: string;
  automaticRedirect?: boolean;
  automaticRedirectDelay?: number;
}

const NotFoundWrapper = styled.div<{ fillScreen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.fillScreen ? ['width: 100vw;', 'height: 100vh;'].join('\n') : ''}
`;

const LimitedWarningHero = styled(WarningHero)`
  max-width: 1280px;
  max-height: 540px;
`;

const RedirectLink = styled(Link)`
  color: ${(props) => props.theme.colours.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const NotFound = (props: INotFoundProps): React.ReactElement => {
  const dynamicHeroProps: { height?: string } = {};
  if (props.heroHeight) {
    dynamicHeroProps.height = props.heroHeight;
  }

  const { t } = useTranslation();
  const history = useHistory();
  const [timeToRedirect, setTimeToRedirect] = useState(
    props.automaticRedirectDelay!
  );

  const updateTimeToRedirect = () => {
    const timeoutToClear = setTimeout(() => {
      if (timeToRedirect > 0) {
        setTimeToRedirect((currentTimeToRedirect) => currentTimeToRedirect - 1);
      } else {
        clearTimeout(timeoutToClear);
        history.replace(props.redirectUrl!);
      }
    }, 1000);
  };

  useEffect(updateTimeToRedirect, [history, props.redirectUrl, timeToRedirect]);

  return (
    <NotFoundWrapper fillScreen={props.wrapperFillScreen!}>
      <Flex width={1} alignItems="center" flexDirection="column" p={48}>
        <LimitedWarningHero width={props.heroWidth} {...dynamicHeroProps} />
        <Text as="h2" fontSize={48} mb={0} textAlign="center">
          {t(props.message!)}
        </Text>
        <Text as="p" textAlign="center">
          <Trans
            i18nKey="common:redirecting"
            count={timeToRedirect}
            values={{
              seconds: timeToRedirect,
              url: `${window.location.host}${props.redirectUrl}`,
            }}
            tOptions={{ interpolation: { escapeValue: false } }}
            components={[
              <RedirectLink to={props.redirectUrl!} key="url" />,
              <Text as="strong" key="timeToRedirect" />,
            ]}
          />
        </Text>
      </Flex>
    </NotFoundWrapper>
  );
};

NotFound.defaultProps = {
  wrapperFillScreen: false,
  heroWidth: '60%',
  message: 'notFound', // i18n key
  automaticRedirect: true,
  redirectUrl: '/projects',
  automaticRedirectDelay: 10,
};

export default NotFound;
