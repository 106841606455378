import Auth from '@aws-amplify/auth';
import {
  Environment,
  IEnvironment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';
import { handleRetrieveFromCache, handleStoreInCache } from './cache';

export async function fetchQuery(operation: any, variables?: any) {
  let token: string;

  const cachedResponse = handleRetrieveFromCache(operation, variables);

  if (cachedResponse) {
    return cachedResponse;
  }

  try {
    const session = await Auth.currentSession();
    token = session.getIdToken().getJwtToken();
  } catch {
    token = '';
  }

  const response = await fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token,
    },
    body: JSON.stringify({
      variables,
      query: operation.text,
    }),
  });

  const parsedResponse = await response.json();

  handleStoreInCache(operation, variables, parsedResponse);

  return parsedResponse;
}

const relayEnvironment: IEnvironment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource(), { gcReleaseBufferSize: 0 }),
});

export default relayEnvironment;
