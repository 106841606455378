import React, { useContext } from 'react';
import ReactLoading, { LoadingProps } from 'react-loading';
import { Box, BoxProps } from 'rebass';
import styled, { ThemeContext } from 'styled-components';

export interface IQuerySpinnerProps {
  wrapper?: {
    mb?: BoxProps['mb'];
  };
  spinner?: LoadingProps;
}

export const spinnerLarge = {
  wrapper: {
    mb: '256px',
  },
};

const QuerySpinnerWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const QuerySpinner: React.FC<IQuerySpinnerProps> = ({
  spinner = {
    type: 'cylon',
    height: 128,
    width: 128,
    className: '',
    delay: 50,
  },
  wrapper = {},
}) => {
  const theme = useContext(ThemeContext);

  return (
    <QuerySpinnerWrapper>
      <Box mx="auto" {...wrapper}>
        <ReactLoading
          type={spinner.type}
          color={spinner.color || theme.colours.primary}
          delay={spinner.delay}
          height={spinner.height}
          width={spinner.width}
          className={spinner.className}
        />
      </Box>
    </QuerySpinnerWrapper>
  );
};

export default QuerySpinner;
