import styled from 'styled-components';

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colours.grey.lighter};
  height: 100%;
  width: 100%;
  padding: 0 48px;
  background: #fff;
`;

export default Header;
