import { Action, ActionCreator } from 'redux';

export enum BuildingActionTypes {
  UPDATE_ERROR = 'BUILDINGS.UPDATE_ERROR',
}

export interface IUpdateErrorAction extends Action {
  payload: {
    id: string;
    error: string;
  };
  type: BuildingActionTypes.UPDATE_ERROR;
}

export const updateError: ActionCreator<IUpdateErrorAction> = (
  id: string,
  error: string
) => ({
  payload: {
    id,
    error,
  },
  type: BuildingActionTypes.UPDATE_ERROR,
});
