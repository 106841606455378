export interface IRouteRecordType {
  path: string;
  get: (...arg: any) => string;
  title?: string;
  subtitle?: string | string[];
  translationNamespace?: string;
}

export type RoutesType = Record<string, Record<string, IRouteRecordType>>;

const routes: RoutesType = {
  auth: {
    authorize: {
      path: '/authorize',
      get: () => '/authorize',
    },
    login: {
      path: '/login',
      get: () => '/login',
    },
    resetPassword: {
      path: '/reset-password/:userName/:confirmationCode',
      get: (userName: string, confirmationCode: string) => {
        return `/reset-password/${userName}/${confirmationCode}`;
      },
    },
    forgotPassword: {
      path: '/forgot-password',
      get: () => '/forgot-password',
    },
  },
  account: {
    create: {
      path: '/accounts/create',
      get: () => '/accounts/create',
      title: 'Add new account',
      translationNamespace: 'account',
    },
    edit: {
      path: '/accounts/edit/:id',
      get: (accountId: string) => `/accounts/edit/${accountId}`,
      title: 'Edit account',
      translationNamespace: 'account',
    },
    home: {
      path: '/accounts',
      get: () => '/accounts',
      title: 'Accounts',
      translationNamespace: 'account',
    },
  },
  partner: {
    create: {
      path: '/partners/create',
      get: () => '/partners/create',
      title: 'Add new partner',
      translationNamespace: 'partner',
    },
    home: {
      path: '/partners',
      get: () => '/partners',
      title: 'Partners',
      translationNamespace: 'partner',
    },
  },
  project: {
    create: {
      path: '/projects/create',
      get: () => '/projects/create',
      title: 'Add new project',
      translationNamespace: 'project',
    },
    edit: {
      path: '/projects/edit/:id',
      get: (projectId: string) => `/projects/edit/${projectId}`,
      title: 'Edit project',
      translationNamespace: 'project',
    },
    home: {
      path: '/projects',
      get: () => '/projects',
      title: 'Projects',
      translationNamespace: 'project',
    },
  },
  apartment: {
    create: {
      path: '/apartments/create',
      get: () => '/apartments/create',
      title: 'Add new apartment',
      translationNamespace: 'apartment',
    },
    edit: {
      path: '/apartments/edit/:id',
      get: (apartmentId: string) => `/apartments/edit/${apartmentId}`,
      title: 'Edit apartment',
      translationNamespace: 'apartment',
    },
    home: {
      path: '/apartments',
      get: () => '/apartments',
      title: 'Apartments',
      translationNamespace: 'apartment',
    },
  },
  house: {
    create: {
      path: '/houses/create',
      get: () => '/houses/create',
      title: 'Add new house',
      translationNamespace: 'house',
    },
    edit: {
      path: '/houses/edit/:id',
      get: (houseId: string) => `/houses/edit/${houseId}`,
      title: 'Edit house',
      translationNamespace: 'house',
    },
    home: {
      path: '/houses',
      get: () => '/houses',
      title: 'Houses',
      translationNamespace: 'house',
    },
  },
  parkingLot: {
    create: {
      path: '/parking-lots/create',
      get: () => '/parking-lots/create',
      title: 'Add new parking lot',
      translationNamespace: 'parkingLot',
    },
    edit: {
      path: '/parking-lots/edit/:id',
      get: (parkingLotId: string) => `/parking-lots/edit/${parkingLotId}`,
      title: 'Edit parking lot',
      translationNamespace: 'parkingLot',
    },
    home: {
      path: '/parking-lots',
      get: () => '/parking-lots',
      title: 'Parking lots',
      translationNamespace: 'parkingLot',
    },
  },
  listing: {
    create: {
      path: '/listings/create',
      get: () => '/listings/create',
      title: 'Create new Listing',
      translationNamespace: 'listing',
    },
    edit: {
      path: '/listings/edit/:id',
      get: (listingId: string) => `/listings/edit/${listingId}`,
      title: 'Edit listing',
      translationNamespace: 'listing',
    },
    clone: {
      path: '/listings/clone/:cloneId',
      get: (listingId: string) => `/listings/clone/${listingId}`,
      title: 'Clone listing',
      translationNamespace: 'listing',
    },
    home: {
      path: '/listings',
      get: () => '/listings',
      title: 'Listings',
      translationNamespace: 'listing',
    },
  },
};

export default routes;
