import * as React from 'react';
import styled from 'styled-components';

import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useNotification } from '@utils/useNotification';
import { INotification, NotificationType } from '@utils/useNotification/types';

type NotificationColorType = Record<
  NotificationType,
  {
    icon: string;
    clearIcon: string;
    background: string;
    IconComponent: React.ComponentType<any>;
  }
>;

const DetailsMapping: NotificationColorType = {
  [NotificationType.Success]: {
    background: '#edf6f6',
    clearIcon: '#d9ecda',
    icon: '#49a5a5',
    IconComponent: CheckCircleIcon,
  },
  [NotificationType.Warning]: {
    background: '#fcf2e6',
    clearIcon: '#fae5cc',
    icon: '#e67d00',
    IconComponent: WarningIcon,
  },
  [NotificationType.Error]: {
    background: '#fbebe7',
    clearIcon: '#f8d6ce',
    icon: '#de350b',
    IconComponent: ErrorIcon,
  },
};

const Container = styled.div<{ type: NotificationType }>`
  width: 100%;
  color: #666666;
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;
  font-size: 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  background: ${({ type }) => DetailsMapping[type].background};

  &:hover {
    animation-play-state: paused;
    background: ${({ type }) => DetailsMapping[type].background};
  }

  .main {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 8px;
    color: ${({ type }) => DetailsMapping[type].icon};
  }

  .clear-icon {
    width: 36px;
    height: 36px;
    color: ${({ type }) => DetailsMapping[type].icon};
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;

    :hover {
      background-color: ${({ type }) => DetailsMapping[type].clearIcon};
    }
  }
`;

interface IProps {
  className?: string;
  notification?: INotification;
}

const NotificationBar = (props: IProps) => {
  const timeoutId = React.useRef<any>();
  const { notification: hookNotification, clearNotification } =
    useNotification();

  const notification = props.notification || hookNotification;

  React.useEffect(() => {
    clearTimeout(timeoutId.current);
    if (!notification || !notification.duration) return;

    timeoutId.current = setTimeout(() => {
      clearNotification();
    }, notification.duration);
  }, [notification, clearNotification]);

  if (!notification) return <></>;

  const {
    type = NotificationType.Success,
    content,
    hideIcon = false,
    hideCloseIcon = false,
  } = notification;

  const Icon = notification.Icon || DetailsMapping[type].IconComponent;

  return (
    <Container type={type} className={props.className}>
      <span className="main">
        {!hideIcon && <Icon data-testid="notif-icon" className="icon" />}
        {typeof content === 'function' ? content() : content}
      </span>
      {!hideCloseIcon && (
        <ClearIcon
          role="button"
          className="clear-icon"
          data-testid="notif-close-icon"
          onClick={clearNotification}
        />
      )}
    </Container>
  );
};

export { NotificationBar };
