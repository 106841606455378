import 'core-js';
import 'isomorphic-fetch';

import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { setEnvironment } from 'relay-compose';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { ThemeProvider } from 'styled-components';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import authConfig from './Authentication/config';
import { getCurrentUser } from './Authentication/getCurrentUser';
import relayEnvironment from './relay/relayEnvironment';
import store from './store';
import GlobalStyle from './theme/global';
import theme, { muiTheme } from './theme/theme';

import './i18n';

setEnvironment(relayEnvironment);
authConfig();

export default (async () => {
  const user = await getCurrentUser();

  const AppWithFeatureFlags = withLDProvider({
    clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID!,
    user,
  })(App);

  return ReactDOM.render(
    <Provider store={store}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <ThemeProvider theme={theme}>
          <MUIThemeProvider theme={muiTheme}>
            <GlobalStyle />
            <AppWithFeatureFlags />
          </MUIThemeProvider>
        </ThemeProvider>
      </RelayEnvironmentProvider>
    </Provider>,
    document.getElementById('root')
  );
})();
