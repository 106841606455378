import * as React from 'react';

export enum NotificationType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export interface INotification {
  type: NotificationType;
  content: string | (() => React.ReactNode);
  duration?: number;
  Icon?: React.ComponentType<any>;
  hideIcon?: boolean;
  hideCloseIcon?: boolean;
}
