import { Reducer } from 'redux';
import { BuildingActionTypes, IUpdateErrorAction } from './actions';

export interface IBuildingState {
  error?: string;
}

export interface IBuildingsState {
  [key: string]: IBuildingState;
}

export const initialState: IBuildingsState = {};

const reducer: Reducer<IBuildingsState, IUpdateErrorAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case BuildingActionTypes.UPDATE_ERROR: {
      state[action.payload.id] = {
        error: action.payload.error,
      };

      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
