import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledAnchor = styled(NavLink)`
  position: relative;
  width: 100%;
  height: 80px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  :hover {
    ${(props) =>
      `background-color: ${props.theme.v2.colours.secondary.default};`}
  }

  svg {
    height: 30px;
    width: 30px;

    path {
      fill: white;
    }
  }

  p {
    margin: 0;
    padding-top: 8px;
    font-size: 13px;
  }

  &.active {
    background-color: ${({ theme }) => theme.v2.colours.secondary.default};

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: white;
    }
  }
`;

const NavbarLink = ({ children, ...navProps }: NavLinkProps) => {
  return (
    <StyledAnchor activeClassName="active" {...navProps}>
      {children}
    </StyledAnchor>
  );
};

export default NavbarLink;
