import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import routes from './routing/routes';

import LoggedInLayout from './routing/LoggedInLayout';
import NotFound from './routing/NotFound';

const LoginForm = React.lazy(() => import('@components/User/LoginForm'));
const ResetPasswordContainer = React.lazy(
  () => import('@components/ResetPassword/ResetPassword')
);
const ForgotPasswordContainer = React.lazy(
  () => import('@components/ForgotPassword/ForgotPasswordContainer')
);

const SSORedirect = () => {
  React.useEffect(() => {
    setTimeout(() => window.location.assign('/projects'), 1000);
  }, []);

  return <div>Loading...</div>;
};

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={[
            routes.account.home.path,
            routes.partner.home.path,
            routes.apartment.home.path,
            routes.house.home.path,
            routes.listing.home.path,
            routes.parkingLot.home.path,
            routes.project.home.path,
          ]}
          component={LoggedInLayout}
        />
        <Route
          exact
          path={routes.auth.authorize.path}
          component={SSORedirect}
        />
        <Route exact path={routes.auth.login.path}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <LoginForm />
          </React.Suspense>
        </Route>
        <Route exact path={routes.auth.resetPassword.path}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <ResetPasswordContainer />
          </React.Suspense>
        </Route>
        <Route exact path={routes.auth.forgotPassword.path}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <ForgotPasswordContainer />
          </React.Suspense>
        </Route>

        <Redirect exact from="/" to={routes.project.home.get()} />

        <Route render={() => <NotFound wrapperFillScreen />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
