import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import routes from './routes';

import { IAppState } from '@src/store';
import {
  Action,
  AuthEntity,
  PermissionsClient,
} from '@src/utils/userPermissions';

interface IProps extends RouteProps {
  authAction: Action;
  authEntity: AuthEntity;
  authRedirect?: string;
}

const PrivateRoute = ({
  authAction,
  authEntity,
  authRedirect,
  ...routeProps
}: IProps) => {
  const userPermissions = useSelector(
    ({ user }: IAppState) => user.permissions
  );
  const permissionClient = new PermissionsClient(userPermissions);

  const hasPermission = permissionClient.can(authAction, authEntity);

  return hasPermission ? (
    <Route {...routeProps} />
  ) : (
    <Redirect to={authRedirect || routes.auth.login.get()} />
  );
};

export default PrivateRoute;
