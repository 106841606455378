import { AnyAction, combineReducers, compose, createStore, Store } from 'redux';
import { FormStateMap, reducer as formReducer } from 'redux-form';
import buildingReducer, {
  IBuildingsState,
} from './components/Projects/ProjectBuildings/reducer';
import { IFormState, inlineEditorReducer } from './utils/inlineSubmitHandler';
import permissionsReducer, {
  IUserState,
} from './utils/userPermissions/reducer';
import notificationReducer, {
  INotificationState,
} from '@utils/useNotification/reducer';

export interface IAppState {
  form: FormStateMap;
  buildings: IBuildingsState;
  inlineEditing: IFormState;
  notification: INotificationState;
  user: IUserState;
}

export const rootReducer = combineReducers<IAppState>({
  form: formReducer,
  buildings: buildingReducer,
  inlineEditing: inlineEditorReducer,
  notification: notificationReducer,
  user: permissionsReducer,
});

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store: Store<IAppState, AnyAction> = createStore(
  rootReducer,
  composeEnhancers()
);

export default store;
