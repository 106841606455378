import Auth from '@aws-amplify/auth';
import { clearCacheByName } from '@src/relay/cache';
import routes from '@src/routing/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Flex } from 'rebass';
import styled from 'styled-components';
import Popover from '../Layout/Popover/Popover';
import mapUserGroup from './mapUserGroup';
import BodyComponent from './Popover/BodyComponent';
import ToggleComponent from './Popover/ToggleComponent';

const UserGroup = styled('label')`
  margin-right: 26px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(131, 131, 131);
  line-height: 27px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
`;

export const groupsKey = 'cognito:groups';

interface IUser {
  attributes: {
    given_name?: string;
    family_name?: string;
  };
  signInUserSession: {
    idToken: {
      payload: {
        [groupsKey]: string[];
      };
    };
  };
  username: string;
}

export const CurrentUser = () => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);

  let displayName = '';
  let groups: string[] = [];
  if (currentUser) {
    const {
      username,
      attributes: { given_name, family_name },
      signInUserSession: {
        idToken: { payload },
      },
    } = currentUser;
    groups = payload[groupsKey];
    displayName =
      given_name && family_name ? `${given_name} ${family_name}` : username;
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((data) => setCurrentUser(data));
  }, []);

  const onLogout = () => {
    Auth.signOut().then(() => {
      clearCacheByName('userPermissionsCache');
      history.push(routes.auth.login.get());
    });
  };

  const allGroups = groups.map(mapUserGroup).join(', ');

  return (
    currentUser && (
      <Flex flexDirection="column" alignItems="flex-end">
        <Popover
          toggleComponent={ToggleComponent}
          bodyComponent={BodyComponent}
          renderProps={{
            logout: onLogout,
            username: displayName,
          }}
        />
        {groups.length > 0 && (
          <UserGroup title={allGroups}>{allGroups}</UserGroup>
        )}
      </Flex>
    )
  );
};

export default CurrentUser;
