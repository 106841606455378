import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { createSelector, Selector } from 'reselect';
import { IAppState } from '../../store';
import { IUserPermission } from './actions';
import { IUserState } from './reducer';

const selectForm = (state: IAppState): IUserState => state.user;

const selectUserPermissions: Selector<IAppState, IUserPermission[]> =
  createSelector(selectForm, (state) => state.permissions);

const selectSession: Selector<IAppState, CognitoUserSession | undefined> =
  createSelector(selectForm, (state) => state.session);

export { selectSession, selectUserPermissions };
