import { steamEntities as AuthEntity } from '@everestate/sdk-permissions';
import PermissionsClient from './PermissionsClient';

export enum Action {
  Update = 'UPDATE',
  Delete = 'DELETE',
  Create = 'CREATE',
  PublishChanges = 'PUBLISH_CHANGES',
}

export { PermissionsClient, AuthEntity };
