import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Action, ActionCreator } from 'redux';

export enum UserPermissionActionTypes {
  SAVE_PERMISSIONS = 'SAVE_PERMISSIONS',
  SAVE_CURRENT_SESSION = 'SAVE_CURRENT_SESSION',
}

export interface IUserPermission {
  id: string;
  role: string;
  entity: string;
  action: string;
  attributes: string;
}

interface ISavePermissionsAction extends Action {
  payload: { permissions: IUserPermission[] };
  type: UserPermissionActionTypes.SAVE_PERMISSIONS;
}

export const savePermissions: ActionCreator<ISavePermissionsAction> = (
  permissions: IUserPermission[]
) => ({
  payload: { permissions },
  type: UserPermissionActionTypes.SAVE_PERMISSIONS,
});

interface ISaveCurrentSession extends Action {
  payload: { session: CognitoUserSession };
  type: UserPermissionActionTypes.SAVE_CURRENT_SESSION;
}

export const saveCurrentSession: ActionCreator<ISaveCurrentSession> = (
  session: CognitoUserSession
) => ({
  payload: { session },
  type: UserPermissionActionTypes.SAVE_CURRENT_SESSION,
});

export type IUserActions = ISavePermissionsAction | ISaveCurrentSession;
