import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Reducer } from 'redux';
import {
  IUserActions,
  IUserPermission,
  UserPermissionActionTypes,
} from './actions';

export interface IUserState {
  session?: CognitoUserSession;
  permissions: IUserPermission[];
}

export const initialState = {
  permissions: [],
};

const reducer: Reducer<IUserState, IUserActions> = (
  state = initialState,
  action
): IUserState => {
  switch (action.type) {
    case UserPermissionActionTypes.SAVE_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    }
    case UserPermissionActionTypes.SAVE_CURRENT_SESSION: {
      return {
        ...state,
        session: action.payload.session,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
