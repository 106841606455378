import { INotification } from './types';

export enum NotificationActionTypes {
  CLEAR_NOTIFICATION = '@notification/CLEAR_NOTIFICATION',
  SHOW_NOTIFICATION = '@notification/SHOW_NOTIFICATION',
}

interface IClearNotificationAction {
  type: NotificationActionTypes.CLEAR_NOTIFICATION;
}

export const clearNotification = (): IClearNotificationAction => ({
  type: NotificationActionTypes.CLEAR_NOTIFICATION,
});

interface IShowNotificationAction {
  payload: { notification: INotification };
  type: NotificationActionTypes.SHOW_NOTIFICATION;
}

export const showNotification = (
  notification: INotification
): IShowNotificationAction => ({
  payload: { notification },
  type: NotificationActionTypes.SHOW_NOTIFICATION,
});

export type INotificationActions =
  | IClearNotificationAction
  | IShowNotificationAction;
