import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './translations/de.json';
import en from './translations/en.json';

export enum LanguageEnum {
  english = 'en',
  german = 'de',
}

export const LocaleEnum = {
  en: 'en_US',
  de: 'de_DE',
} as const;

export const resources = {
  [LanguageEnum.english]: en,
  [LanguageEnum.german]: de,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: LanguageEnum.german,
    defaultNS: 'common',
    fallbackNS: [
      'common',
      'project',
      'building',
      'apartment',
      'listing',
      'house',
    ],
    detection: {
      order: ['localStorage'],
    },
    compatibilityJSON: 'v3',
    interpolation: { skipOnVariables: false },
    debug: process.env.NODE_ENV === 'development',
  });

export default i18n;
