import Auth from '@aws-amplify/auth';
import { LDUser } from 'launchdarkly-js-client-sdk';

export async function getCurrentUser(): Promise<LDUser> {
  const userInfo = await Auth.currentUserInfo();

  if (!userInfo) {
    return { anonymous: true };
  }

  return {
    key: userInfo.attributes.email,
    email: userInfo.attributes.email,
    name: userInfo.username,
  };
}
