import { Variables } from 'react-relay';
import { QueryResponseCache } from 'relay-runtime';

export const userPermissionsCacheTTL = 12 * 60 * 60 * 1000;

export const userPermissionsCache = new QueryResponseCache({
  size: 250,
  ttl: userPermissionsCacheTTL,
});

export interface ICaches {
  userPermissionsCache: QueryResponseCache;
}

export const caches: ICaches = {
  userPermissionsCache,
};

const handleRetrieveFromCache = (operation: any, variables: Variables) => {
  if (operation.name === 'userPermissionsQuery') {
    const cachedUserPermissions = userPermissionsCache.get(
      operation.name,
      variables
    );

    if (cachedUserPermissions) {
      return cachedUserPermissions;
    }
  }

  return null;
};

const handleStoreInCache = (
  operation: any,
  variables: Variables,
  payload: any
) => {
  if (
    operation.name === 'userPermissionsQuery' &&
    payload.data.userPermissions
  ) {
    userPermissionsCache.set(operation.name, variables, payload);
  }
};

const clearCacheByName = (cacheName: keyof ICaches) => {
  const cacheToClear = caches[cacheName];

  if (!cacheToClear) {
    console.warn('Trying to clear non-existant cache!');
    return;
  }

  cacheToClear.clear();
};

export { handleRetrieveFromCache, handleStoreInCache, clearCacheByName };
