/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { createGlobalStyle } from 'styled-components';

import theme from '../theme/theme';

// @ts-ignore
import FuturaBookEot from '@assets/fonts/futura-book.eot';
// @ts-ignore
import FuturaBookTtf from '@assets/fonts/futura-book.ttf';
// @ts-ignore
import FuturaBookWoff from '@assets/fonts/futura-book.woff';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FuturaMediumEot from '@assets/fonts/futura-medium.eot';
// @ts-ignore
import FuturaMediumTtf from '@assets/fonts/futura-medium.ttf';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FuturaMediumWoff from '@assets/fonts/futura-medium.woff';

// @ts-ignore
import FuturaHeavyEot from '@assets/fonts/futura-heavy.eot';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FuturaHeavyTtf from '@assets/fonts/futura-heavy.ttf';
// @ts-ignore
import FuturaHeavyWoff from '@assets/fonts/futura-heavy.woff';

// @ts-ignore
import FuturaLightEot from '@assets/fonts/futura-light.eot';
// @ts-ignore
import FuturaLightTtf from '@assets/fonts/futura-light.ttf';
// @ts-ignore
import FuturaLightWoff from '@assets/fonts/futura-light.woff';

export default createGlobalStyle`
    @font-face {
      font-family: 'FuturaBook';
      src: url(${FuturaBookEot});
      src: url(${FuturaBookEot}) format('embedded-opentype'),
           url(${FuturaBookWoff}) format('woff'),
           url(${FuturaBookTtf}) format('truetype');
      font-weight: ${theme.fontWeights.normal};
    }

    @font-face {
      font-family: 'FuturaHeavy';
      src: url(${FuturaHeavyEot});
      src: url(${FuturaHeavyEot}) format('embedded-opentype'),
           url(${FuturaHeavyWoff}) format('woff'),
           url(${FuturaMediumTtf}) format('truetype');
      font-weight: ${theme.fontWeights.bold};
    }

    @font-face {
      font-family: 'FuturaLight';
      src: url(${FuturaLightEot});
      src: url(${FuturaLightEot}) format('embedded-opentype'),
           url(${FuturaLightWoff}) format('woff'),
           url(${FuturaLightTtf}) format('truetype');
      font-weight: ${theme.fontWeights.light};
    }

    * {
      box-sizing: border-box;
      font-size: 16px;
      font-family: ${theme.fonts.sans};
    }

    html {
      height: 100%;
    }

    body {
      margin: 0;
      background: ${theme.v2.colours.grey.lightest};
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    button {
      border: 0;
      padding: 0;
      font: inherit;
      outline: none;
      cursor: pointer;
    }

    select, input, textarea {
      font: inherit;
      outline: none;
    }

    .full-width {
      width: 100%;
    }

    .bold {
      font-weight: 900;
    }

    .spinner {
      -webkit-animation: spin 2s infinite linear;

      @keyframes spin {
        0%  {-webkit-transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);}
      }
    }

    .prewrap {
      white-space: pre-wrap;
    }

    .pointer {
      cursor: pointer;
    }
  `;
