import {
  Account as AccountsSvg,
  Apartment as ApartmentsSvg,
  Listing as ListingsSvg,
  ParkingLot as ParkingLotsSvg,
  Partner as PlatformPartnersSvg,
  Project as ProjectsSvg,
} from '@assets/images';
import { ReactComponent as HousesSvg } from '@assets/images/cottage/baseline.svg';
import routes from '@src/routing/routes';
import { IAppState } from '@src/store';
import {
  Action,
  AuthEntity,
  PermissionsClient,
} from '@src/utils/userPermissions';
import { selectUserPermissions } from '@src/utils/userPermissions/selectors';
import { Order, OrderOperation } from '@utils/useChangeOrder/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as EverestateSvg } from '@assets/images/everestate-sign.svg';
import NavbarLink from './NavbarLink';

const Wrapper = styled('div')`
  position: fixed;
  z-index: 100;
  min-width: 85px;
  min-height: 100vh;
  border-right: 1px solid ${(props) => props.theme.v2.colours.secondary.lighter};
  background: ${(props) => props.theme.v2.colours.secondary.dark};
`;

const LogoWrapper = styled('div')`
  height: 80px;
  width: 100%;
  border-bottom: 1px solid
    ${(props) => props.theme.v2.colours.secondary.default};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
`;

const LinksWrapper = styled('div')`
  padding-top: 40px;
`;

const defaultFilters = {
  visibility: 'visible',
};

const apartmentsDefaultOrder = {
  orders: [
    { name: 'unitType', order: Order.DESC },
    { name: 'apartmentNumber', order: Order.ASC },
  ],
};

const parkingLotsDefaultOrder = {
  orders: [
    {
      name: 'parkingNumber',
      order: Order.ASC,
      operation: OrderOperation.LENGTH,
    },
    { name: 'parkingNumber', order: Order.ASC },
  ],
};

const housesDefaultOrder = {
  orders: [
    {
      name: 'houseNumber',
      order: Order.ASC,
      operation: OrderOperation.LENGTH,
    },
    { name: 'houseNumber', order: Order.ASC },
  ],
};

interface INavbarProps {
  canViewAccount: boolean;
}

const Navbar: React.FC<INavbarProps> = (props) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper id="navbar-wrapper">
      <LogoWrapper>
        <EverestateSvg />
      </LogoWrapper>
      <LinksWrapper>
        {props.canViewAccount && (
          <NavbarLink to={routes.account.home.get()}>
            <AccountsSvg />
            <p>{t('accounts')}</p>
          </NavbarLink>
        )}
        <NavbarLink to={routes.project.home.get()}>
          <ProjectsSvg />
          <p>{t('projects')}</p>
        </NavbarLink>
        <NavbarLink
          to={{
            pathname: routes.apartment.home.get(),
            search: new URLSearchParams({
              orders: JSON.stringify(apartmentsDefaultOrder.orders),
              ...defaultFilters,
            }).toString(),
          }}
        >
          <ApartmentsSvg />
          <p>{t('apartments')}</p>
        </NavbarLink>
        <NavbarLink
          to={{
            pathname: routes.house.home.get(),
            search: new URLSearchParams({
              orders: JSON.stringify(housesDefaultOrder.orders),
              ...defaultFilters,
            }).toString(),
          }}
        >
          <HousesSvg style={{ width: '36px', height: '36px' }} />
          <p>{t('houses')}</p>
        </NavbarLink>
        <NavbarLink
          to={{
            pathname: routes.parkingLot.home.get(),
            search: new URLSearchParams({
              orders: JSON.stringify(parkingLotsDefaultOrder.orders),
              ...defaultFilters,
            }).toString(),
          }}
        >
          <ParkingLotsSvg />
          <p>{t('parkingLots')}</p>
        </NavbarLink>
        <NavbarLink to={routes.listing.home.get()}>
          <ListingsSvg />
          <p>{t('listings')}</p>
        </NavbarLink>
        <NavbarLink to={routes.partner.home.get()}>
          <PlatformPartnersSvg />
          <p>{t('platformPartners')}</p>
        </NavbarLink>
      </LinksWrapper>
    </Wrapper>
  );
};

export const mapStateToProps = (state: IAppState) => {
  const userPermissions = selectUserPermissions(state);
  const permissionsClient = new PermissionsClient(userPermissions);

  return {
    canViewAccount: permissionsClient.can(Action.Create, AuthEntity.user),
  };
};

export default connect(mapStateToProps)(Navbar);
