import styled from 'styled-components';

export const ACCOUNT_WRAPPER_WIDTH = 192;

const AccountWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  height: 100%;
  width: ${ACCOUNT_WRAPPER_WIDTH}px;
`;

export default AccountWrapper;
