import { ITheme, theme } from '@everestate/ui-toolbelt/lib/themeV2';
import { createTheme } from '@material-ui/core/styles';
// Required to use type import, as a plain side-effect import will be emitted to runtime.
import type {} from '@material-ui/lab/themeAugmentation';

const muiTheme = createTheme({
  overrides: {
    MuiAutocomplete: {
      root: {
        // Add 5px to the right paddings and right position
        // In order to align icons for MuiAutocomplete outined variant with other components
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
          {
            right: '14px',
          },
        '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
          {
            paddingRight: '43px',
          },
        '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
          {
            paddingRight: '70px',
          },
        // Make clear button always visible, rather than on hover only
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator':
          {
            visibility: 'visible',
          },
      },
      popupIndicatorOpen: {
        '& .error-icon, & .loading-icon, & .done-icon': {
          transform: 'rotate(180deg)',
        },
      },
      paper: {
        margin: '8px 0',
        border: `1px solid ${theme.v2.colours.primary.default}`,
      },
      option: {
        // Hover
        '&[data-focus="true"]': {
          backgroundColor: theme.v2.colours.primary.lightest,
          color: theme.v2.colours.primary.default,
        },
        // Selected
        '&[aria-selected="true"], &[aria-selected="true"][data-focus="true"]': {
          backgroundColor: theme.v2.colours.primary.default,
          color: 'white',
        },
      },
    },
    MuiTextField: {
      root: {
        // Label styling
        '& .MuiInputLabel-root': {
          transition: 'color 0s',
          color: theme.v2.colours.grey.default,
        },
        '& .MuiInputLabel-root.Mui-disabled': {
          color: theme.v2.colours.grey.default,
        },
        '&:hover .MuiInputLabel-root.Mui-disabled': {
          color: theme.v2.colours.grey.default,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.v2.colours.primary.default,
        },
        '& .MuiInputLabel-root.Mui-error': {
          color: theme.v2.colours.danger.default,
        },
        '& .MuiInputLabel-root.Mui-error.Mui-focused': {
          color: theme.v2.colours.danger.default,
        },
        '&:hover .MuiInputLabel-root': {
          color: theme.v2.colours.grey.darker,
        },
        '&:hover .MuiInputLabel-root.Mui-focused': {
          color: theme.v2.colours.primary.default,
        },
        '&:hover .MuiInputLabel-root.Mui-error': {
          color: theme.v2.colours.danger.default,
        },
        '&:hover .MuiInputLabel-root.Mui-error.Mui-focused': {
          color: theme.v2.colours.danger.default,
        },
        // Outlined input styling
        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
          textOverflow: 'ellipsis',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.v2.colours.grey.default,
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.v2.colours.grey.darker,
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.v2.colours.danger.default,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.v2.colours.primary.default,
          },
        '& .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.v2.colours.danger.default,
          },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.v2.colours.grey.light,
          },
        // Remove grey background color when select is focused
        '& .MuiOutlinedInput-root .MuiSelect-outlined:focus': {
          backgroundColor: 'white',
        },
        // Outlined select fix padding so that icon is positioned correctly
        // Same as input icons
        '& .MuiOutlinedInput-root .MuiSelect-outlined': {
          textOverflow: 'ellipsis',
          paddingRight: '39px',
        },
        '& .MuiOutlinedInput-root .MuiSelect-iconOutlined': {
          right: '14px',
        },
        // Helper text when reserving empty space for validation errors
        '& .MuiFormHelperText-root span': {
          fontSize: '0.75rem',
        },
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        wordSpacing: '0.25ch',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: theme.v2.colours.grey.default,
      },
    },
    MuiIconButton: {
      root: {
        color: theme.v2.colours.grey.default,
        '&:hover': {
          backgroundColor: theme.v2.colours.grey.lighter,
        },
      },
      sizeSmall: {
        padding: '4px',
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: theme.v2.colours.grey.light,
      },
    },
    MuiIcon: {
      colorDisabled: {
        color: theme.v2.colours.grey.light,
      },
    },
  },
  typography: {
    fontFamily: 'FuturaBook, FuturaLight, FuturaHeavy',
  },
  palette: {
    text: {
      primary: theme.v2.colours.grey.darker,
      secondary: theme.v2.colours.grey.default,
      disabled: theme.v2.colours.grey.light,
    },
    primary: {
      main: theme.v2.colours.primary.default,
      light: theme.v2.colours.primary.lighter,
      dark: theme.v2.colours.primary.dark,
    },
    secondary: {
      main: theme.v2.colours.secondary.default,
      light: theme.v2.colours.secondary.lighter,
      dark: theme.v2.colours.secondary.dark,
    },
    error: {
      main: theme.v2.colours.danger.default,
      light: theme.v2.colours.danger.lightest, // TODO: This shade might be too light
    },
    // ! Our grey color palette is more limited
    // ! Some levels are mapped to the same value
    grey: {
      50: theme.v2.colours.grey.lightest,
      100: theme.v2.colours.grey.lightest,
      200: theme.v2.colours.grey.lighter,
      300: theme.v2.colours.grey.lighter,
      400: theme.v2.colours.grey.light,
      500: theme.v2.colours.grey.default,
      600: theme.v2.colours.grey.default,
      700: theme.v2.colours.grey.dark,
      800: theme.v2.colours.grey.darker,
      900: theme.v2.colours.grey.darker,
    },
  },
});

export type { ITheme };
export { theme as default, muiTheme };
