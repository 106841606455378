import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useShallowSelector } from '@utils/useShallowSelector';
import { INotification, NotificationType } from './types';
import {
  clearNotification as clearNotificationAction,
  showNotification as showNotificationAction,
} from './actions';

type OptionsType = {
  clearOnUnmount: boolean;
};
const useNotification = (
  { clearOnUnmount }: OptionsType = { clearOnUnmount: true }
) => {
  const dispatch = useDispatch();

  const notification = useShallowSelector(
    ({ notification }) => notification.notification
  );

  React.useEffect(() => {
    if (clearOnUnmount) {
      return () => {
        dispatch(clearNotificationAction());
      };
    }
  }, [dispatch, clearOnUnmount]);

  const clearNotification = React.useCallback(() => {
    dispatch(clearNotificationAction());
  }, [dispatch]);

  const showNotification = React.useCallback(
    (notification: INotification) =>
      dispatch(showNotificationAction(notification)),
    [dispatch]
  );

  const showSuccessNotification = React.useCallback(
    (notification: Omit<INotification, 'type'>) => {
      dispatch(
        showNotificationAction({
          type: NotificationType.Success,
          duration: 5000,
          ...notification,
        })
      );
    },
    [dispatch]
  );

  const showErrorNotification = React.useCallback(
    (notification: Omit<INotification, 'type'>) => {
      dispatch(
        showNotificationAction({
          type: NotificationType.Error,
          ...notification,
        })
      );
    },
    [dispatch]
  );

  const showWarningNotification = React.useCallback(
    (notification: Omit<INotification, 'type'>) => {
      dispatch(
        showNotificationAction({
          type: NotificationType.Warning,
          ...notification,
        })
      );
    },
    [dispatch]
  );

  return {
    notification,
    clearNotification,
    showNotification,
    showErrorNotification,
    showSuccessNotification,
    showWarningNotification,
  };
};

export { useNotification };
