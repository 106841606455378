import { IFieldConfig as IConfig } from '@everestate/ui-toolbelt/lib/inline-editor';
import { AuthEntity } from '../userPermissions';

export enum EntityTypes {
  Project = 'Project',
  ProjectTranslation = 'ProjectTranslation',
  Image = 'Image',
  Apartment = 'Apartment',
  ApartmentVariation = 'ApartmentVariation',
  Building = 'Building',
  Listing = 'Listing',
  Portal = 'Portal',
  User = 'User',
  UserRole = 'UserRole',
  ParkingLot = 'ParkingLot',
  House = 'House',
  Unit = 'Unit',
  ProjectDeveloperCompanyName = 'ProjectDeveloperCompanyName',
  ProjectDeveloperOrganisation = 'ProjectDeveloperOrganisation',
  ProjectManagementDirector = 'ProjectManagementDirector',
}

/** Interface used for field configuration for redux forms and inline editing */
export interface IFieldConfig extends IConfig {
  entityType: EntityTypes;
  authEntityType?: AuthEntity;
  missingFieldMap?: string;
}

export const asIFieldConfig = <T extends { [key: string]: IFieldConfig }>(
  arg: T
): T => arg;

export type {
  IFormState,
  IFormFieldState,
} from '@everestate/ui-toolbelt/lib/inline-editor';
export {
  getFormFieldConfig,
  getFormFields,
  inlineSubmitHandler as default,
  selectFormField,
  withInlineEditor,
  inlineEditorReducer,
} from '@everestate/ui-toolbelt/lib/inline-editor';
